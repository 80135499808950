/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import useSiteMetadata from "@components/hooks/use-site-metadata"
import { getSrc } from "gatsby-plugin-image"

function SEO({ description, lang, meta, title, imageData, pathname, canonicalUrl }) {
  const siteMetadata = useSiteMetadata();

  var titleTemplate = `%s | ${siteMetadata.title}`
  if (title === 'Harper') {
    titleTemplate = `${siteMetadata.title} | Learn to train and parent your dog from the comfort of home`
  }

  const url = pathname ? `${siteMetadata.siteUrl}${pathname}` : null;

  const imageUrl = `https://snoopy.harper.dog/${imageData ? getSrc(imageData) : "/og-image-facebook.png"}`;
  const imageWidth = imageData?.width || 1280;
  const imageHeight = imageData?.height || 630;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      link={canonicalUrl || url ? [
        {
          rel: "canonical",
          href: canonicalUrl || url,
        },
      ] : []}
      meta={[
        {
          name: `description`,
          content: description || siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: "Harper",
        },
        {
          property: `og:description`,
          content: description || siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
      ].concat(meta)}
    >
      <meta name="title" content={title} />
      <meta name="description" content={description || siteMetadata.description} />
      <meta name="google-site-verification" content="bEsWPwehah65Tx-6aBEf-oZM1h4s8HMjxnPyrbjnVrU" />
      <meta property="image" content={imageUrl} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url || siteMetadata.siteUrl} />
      <meta property="og:site_name" content="Harper" />
      <meta property="og:description" content={description || siteMetadata.description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="fb:app_id" content="459633881862193" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url || siteMetadata.siteUrl} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description || siteMetadata.description} />
      <meta name="twitter:site" content="@harperdogapp" />
      <meta name="twitter:creator" content="@harperdogapp" />
      <meta name="twitter:app:name:iphone" content="Harper" />
      <meta name="twitter:app:id:iphone" content="1338605261" />
      <meta name="p:domain_verify" content="3506e489079430af482e5a9e28b89aea"/>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  imageData: PropTypes.object, // TODO: this has a specific shape, just not in our control
  pathname: PropTypes.string,
}

export default SEO

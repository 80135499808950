import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const appNamePattern = /Harper/;

const BodyClass = ({
  bodyClassName,
  children,
  forceInApp,
}) => {
  const inAppUserAgent = typeof window !== `undefined` && window.navigator && window.navigator.userAgent &&
    appNamePattern.test(window.navigator.userAgent);
  const appClassName = (forceInApp || inAppUserAgent ? 'within-harper' : 'not-within-harper');
  const fullBodyClassName = `${appClassName} ${bodyClassName || ""}`;

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: fullBodyClassName,
        }}
      />
      {children}
    </>
  )
}

BodyClass.propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  forceInApp: PropTypes.bool,
}

export default BodyClass
